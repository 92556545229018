.ambiente {
    position: absolute;
    float: left;
    left: -50px;
    top: 26px;
    font-size: 15pt;
    transform: rotate(-45deg);
    background-color: darkgreen;
    width: 200px;
    text-align: center;
    z-index: 1035;
}

.ambiente > span {
    color: white;
}