.loader-container{
    position: fixed;
    width: 60px;
    height: 60px;
    margin: -30px -30px;
    top: 50%;
    left: 50%;
    z-index: 999999;
}

.loader-container .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border: solid 4px transparent;
    border-top-color: #28a745;
    border-left-color: #28a745;
    border-radius: 50%;
    animation: loader 1.4s linear infinite;
}

.loader-container .loader2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    border: solid 4px transparent;
    border-top-color: #c82333;
    border-left-color: #c82333;
    border-radius: 50%;
    animation: loader2 1.2s linear infinite;
}

@keyframes loader{
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100%{
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes loader2{
    0%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100%{
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}